.App {
  display: flex;
  flex: 1;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ant-picker .ant-picker-borderless {
  border: 2px solid black !important;
  padding: 5px !important;
}

.watermark {
  visibility: hidden !important;
}
.container1 {
  display: block;
  position: absolute;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  top: 2rem;
  right: 2rem;
  z-index: 5;
}
.imageExercise,
.imageExerciseSingal {
  /* margin-top: 15% !important; */
  /* transform: translateY(-50%); */
  padding: 20px;
  width: 50%;
}

.imageExerciseSingal {
  padding-top: 30px;
}

.imageExercise video,
.imageExercise img {
  width: 50% !important;
  flex: 50%;
}
.img-box {
  background: #fff;
  padding: 1rem;
  width: calc(50vw - 5px);
  text-align: center;
  margin: 0.5rem;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.imageExercise {
  min-height: 100px !important;
}
.carousel-control-next {
  opacity: 0;
  height: 25px;
  width: 25px;
  right: 2rem;
  top: 2rem;
}
/* Hide the browser's default checkbox */

.container1 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */

.checkmark {
  position: relative;
  display: block;
  height: 1.25em;
  width: 1.25em;
  border: 2px solid rgb(141, 141, 141);
  border-radius: 10%;
  box-sizing: border-box;
}
/* On mouse-over, add a grey background color */

.container1:hover input ~ .checkmark {
  background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */

.container1 input:checked ~ .checkmark {
  background-color: #a4c408;
}
/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */

.container1 input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */

.container1 .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media only screen and (max-width: 600px) {
  .responsive-card {
    flex-direction: column !important;
  }
  .imageExercise,
  .imageExerciseSingal {
    width: 100%;
  }
  .description-text {
    font-size: 16px !important;
  }
}
.MuiInput-underline.Mui-disabled:before {
  border-bottom-style: none !important;
}
.button-hover {
  padding: 0;
  margin: 10px 0 !important;
}
/* .button-hover :hover{
  background-color: #fff;
  padding: 1rem;

} */
.react-switch-bg {
  background: rgb(217, 231, 220) !important;
  height: 25px !important;
  width: 50px !important;
}
.colorLiveBtn > .react-switch-bg {
  background: #231f20 !important;
}
.removeColorLiveBtn > .react-switch-bg {
  background: rgb(217, 231, 220) !important;
}
.react-switch-handle {
  background: white !important;
  height: 23px !important;
  width: 23px !important;
}
.colorLiveBtn > .react-switch-handle {
  background: #a2c127 !important;
}
.removeColorLiveBtn > .react-switch-handle {
  background: white !important;
}
.icons {
  font-size: 1.5rem !important;
}

.MuiSelect-select:focus {
  background-color: #fff !important;
}
@media only screen and (min-width: 260px) and (max-width: 400px) {
  .width-remove {
    width: auto !important;
    padding: 0 !important;
    flex: 1 !important;
  }
  .pd-l {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  #DayIdentifierButton {
    padding: 1px 3px;
  }
  .program {
    width: 80% !important;
  }
  .program-image {
    padding: 0 1rem !important;
  }
  .description-text {
    font-size: 16px !important;
  }
}

.containera {
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.containera input:checked ~ .checkmark {
  background-color: #a4c40a;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.containera input:checked ~ .checkmark:after {
  display: block;
}

.containera .checkmark:after {
  content: '';
  position: absolute;
  top: 0;
  left: 25%;
  transform-origin: center center;
  width: 50%;
  height: 75%;
  border: solid white;
  border-width: 0 0.15em 0.15em 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: trasnlate(-50%, -50%);
  width: 80%;
  height: 80%;
  border-width: 0 3px 3px 0;
  border: solid white;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); */