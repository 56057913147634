
.flex-wrap-mobile-video {
    display: flex;
    max-height: 100vh;
    overflow-y: auto;
}
.result-box-section, .result-box-section-mobile {
    min-width: 230px;
    margin-top: 64px;
    background: #57575a;
    color: #d8e6dc;
}

.result-box-tab {
    display: none;
}

.result-box-section-mobile {
    display: none;
}

.result-box-section {
    display: block;
}

@media only screen and (max-width: 767px) {
    .result-box-tab {
        display: flex;
    }
    .result-box-section-mobile {
        margin-top: 0px;
        display: block;
    }
    .result-box-section {
        display: none;
    }
    .flex-wrap-mobile-video {
        flex-wrap: wrap;
        display: block;
        max-height: auto;
    }
}

