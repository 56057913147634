.root {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;

}

.body {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: #d8e6dc!important;
}

.title {
  display: flex;
  flex: 0.1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}